



















































































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import User from '@/modules/user/models/user.model';
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import { IProjectPayload } from '@/modules/project/interfaces/project-response.interface';
import SelectOption from '@/shared/classes/components/form/select-option';
import {
  ProjectStatusKeys,
  ProjectStatusKeysData, ProjectTypeKeys, ProjectTypeKeysData
} from '@/shared/configs/project.config';
import ProjectService from '@/services/ProjectService';
import ProjectsDelete from '@/modules/project/ProjectsDelete.vue';

@Component({
  components: { PageHeader, ClientNotFound, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class ProjectsEdit extends Vue {
  FieldSizes = FieldSizes
  error: any = null
  user!: User
  loading: boolean = true

  private form: IProjectPayload | null = null

  private statusOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectStatusKeys.active).setTitle(ProjectStatusKeysData[ProjectStatusKeys.active]),
    new SelectOption().setKey(ProjectStatusKeys.disabled).setTitle(ProjectStatusKeysData[ProjectStatusKeys.disabled])
  ]

  private typeOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectTypeKeys.contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.contract]),
    new SelectOption().setKey(ProjectTypeKeys.programing_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.programing_contract]),
    new SelectOption().setKey(ProjectTypeKeys.warranty).setTitle(ProjectTypeKeysData[ProjectTypeKeys.warranty]),
    new SelectOption().setKey(ProjectTypeKeys.without_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.without_contract])
  ]

  created(): void {
    this.getData()
  }

  getData(): void {
    this.loading = true
    ProjectService.getProject(this.$route.params.id).then(response => {
      this.form = {
        client_id: 0,
        url: '',
        valid_until: '',
        status: '',
        cms_type: '',
        month_price: 0,
        type: '',
        comment: '',
        price_per_hour: 0
      }

      this.form.client_id = response.client_id
      this.form.url = response.url
      this.form.valid_until = response.valid_until
      this.form.status = response.status
      this.form.cms_type = response.cms_type
      this.form.month_price = response.month_price
      this.form.type = response.type
      this.form.comment = response.comment
      this.form.price_per_hour = response.price_per_hour

      if (this.form.url) {
        this.$root.$emit('headingChange', {
          heading: this.form.url
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  submit(): void {
    if (!this.form) {
      return
    }
    this.loading = true
    ProjectService.updateProject(this.form, this.$route.params.id).then(() => {
      this.loading = false
      this.$router.push({ name: GeneralRoutes.projectsIndex })
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: ProjectsDelete,
      id: this.$route.params.id,
      props: {
        title: __('views.projects.delete.title'),
        size: 'md',
        submitLabel: __('form.delete')
      }
    })
  }
}
