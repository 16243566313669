export enum DefaultTags {
  client = 'client',
  date = 'date',
  manager = 'manager',
  docNo = 'documentNumber',
  user = 'user',
  items = 'offer',
  worker = 'employee',
  expired_at = 'expiredAt'
}

export const DefaultTagValues: any = {
  [DefaultTags.client]: {
    value: 'Klientas',
    keys: [
      '{client_company_name}',
      '{client_address}',
      '{client_company_code}',
      '{client_director}',
      '{client_vat_code}',
      '{client_full_name}',
      '{client_email}',
      '{client_phone}'
    ]
  },
  [DefaultTags.date]: {
    value: 'Data',
    keys: ['{date}']
  },
  [DefaultTags.expired_at]: {
    value: 'Galiojimo data',
    keys: []
  },
  [DefaultTags.manager]: {
    value: 'Vadybininkas',
    keys: [
      '{manager_first_name}',
      '{manager_last_name}',
      '{manager_full_name}',
      '{manager_phone}',
      '{manager_email}',
      '{manager_type}',
      '{manager_position}',
      '{manager_sign}'
    ]
  },
  [DefaultTags.docNo]: {
    value: 'Dokumento numeris',
    keys: ['{document_number}']
  },
  [DefaultTags.user]: {
    value: 'Naudotojas',
    keys: [
      '{user_name}',
      '{user_surname}',
      '{user_full_name}',
      '{user_address}',
      '{user_personal_code}',
      '{user_email}',
      '{user_phone}'
    ]
  },
  [DefaultTags.items]: {
    value: 'Paslaugos',
    keys: ['{offer_table}']
  },
  [DefaultTags.worker]: {
    value: 'Darbuotojas',
    keys: [
      '{employee_full_name}',
      '{employee_personal_code}',
      '{employee_email}',
      '{employee_phone}',
      '{employee_position}',
      '{employee_address}'
    ]
  }
}
