














































































import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__';
import DataTable from '@/shared/components/data-table/DataTable.vue';
import { tableCount } from '@/helpers/tableCount';
import ProcurementCompany from '@/modules/offer/models/procurement-company.model';
import { GeneralRoutes } from '@/router/routes/general';
import ProcurementService from '@/services/ProcurementService';
import { IMeta } from '@/shared/interfaces/response.interface';
import SelectOption from '@/shared/classes/components/form/select-option';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import IProcurementCompanyResponse from '@/modules/offer/interfaces/procurement-company-response.interface';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import FormField from '@/shared/components/form/FormField.vue';
import Field, { FieldSizes } from '@/shared/classes/components/form/field';
import _ from 'lodash';
import Pagination from '@/shared/components/data-table/Pagination.vue';

@Component({
  components: { Pagination, FormField, TableLoader, DataTable },
  methods: { __ }
})
export default class ProcurementCompaniesList extends Vue {
  loading = true
  memorizing = false
  GeneralRoutes = GeneralRoutes
  meta: IMeta | undefined | null = null
  perPage = 10
  tableId = 'procurementCompanies'
  perPageOptions: SelectOption[] = PerPageOptions
  scrollSyncInitialed: boolean = false
  error: string = ''
  dismissCountDown: number = 0
  searchField: Field = new Field()
    .setKey('q')
    .setShowClear(true)
    .setSize(FieldSizes.null)
    .setPlaceholder('Ieškoti...')

  requestFields: TableField[] = [
    { key: 'title', label: __('views.procurement.table.columns.title'), sortable: false },
    { key: 'url', label: __('views.procurement.table.columns.url'), sortable: false },
    { key: 'procurementCount', label: __('views.procurement.table.columns.procurementCount'), sortable: false },
    { key: 'latestProcurementDate', label: __('views.procurement.table.columns.latestProcurementDate'), sortable: false },
    { key: 'details', label: __('views.procurement.table.columns.isMemorized'), sortable: false }
  ]

  companyItems: ProcurementCompany[] = []

  private debouncedSearch = _.debounce(this.getProcurementCompanies, 400)

  @Watch('searchField.value')
  private handleSearch(): void {
    this.debouncedSearch()
  }

  perPageChange(): void {
    tableCount.setRowCount(this.tableId, this.perPage)
    this.getProcurementCompanies()
  }

  getProcurementCompanies(page: string | number = 1) {
    this.loading = true
    this.companyItems = []
    ProcurementService.getMemorizedCompanies({
      perPage: this.perPage,
      page,
      q: this.searchField.value || undefined
    }).then(response => {
      this.meta = response.meta
      response.data.forEach((procurementCompany: IProcurementCompanyResponse) => {
        this.companyItems.push(new ProcurementCompany(procurementCompany))
      })
    }).finally(() => {
      this.loading = false
      this.memorizing = false
      setTimeout(() => {
        this.setStickyHeader()
      }, 100)
    })
  }

  goToCompany(item: any) {
    this.$router.push({ name: GeneralRoutes.procurementCompanyInner, params: { id: item.id.toString() } })
  }

  toggleMemorize(item: any) {
    this.memorizing = true
    ProcurementService.memorizeCompany({
      company_id: item.id,
      is_memorized: !(item.details?.is_memorized)
    }).then(() => {
      this.getProcurementCompanies(this.meta?.current_page || 1)
    })
      .catch((error: any) => {
        this.error = error.response.data.message
        this.dismissCountDown = 10
        this.memorizing = false
      })
  }

  setStickyHeader() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    this.matchTableColumnWidths()
    window.addEventListener('resize', this.matchTableColumnWidths)
    const tableWrapper = tableWrap?.querySelector('.table-responsive')
    if (tableWrapper && !this.scrollSyncInitialed) {
      this.scrollSyncInitialed = true
      tableWrapper.addEventListener('scroll', this.syncScrollPositions)
    }
  }

  syncScrollPositions() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    const tableWrapper = tableWrap?.querySelector('.table-responsive')
    const headerTableWrapper = tableWrap?.querySelector('.header-table-wrapper')

    if (headerTableWrapper && tableWrapper) {
      headerTableWrapper.scrollLeft = tableWrapper.scrollLeft
    }
  }

  matchTableColumnWidths() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    const headerTable: HTMLElement | null = tableWrap?.querySelector('.header-table')
    const bodyTable: HTMLElement | null = tableWrap?.querySelector('.body-table')
    const headerCells = headerTable?.querySelectorAll('th')
    const bodyCells = bodyTable?.querySelectorAll('tbody tr:first-child td')
    const bodyHeader = bodyTable?.querySelector('thead')

    if (headerTable && bodyTable && bodyHeader) {
      bodyTable.style.marginTop = `-${bodyHeader.offsetHeight}px`
    }

    if (!headerCells || !bodyCells || headerCells.length !== bodyCells.length) {
      setTimeout(() => {
        this.matchTableColumnWidths()
      }, 100)
      return
    }

    let totalWidth = 0
    bodyCells.forEach((cell: any, index) => {
      totalWidth += cell.offsetWidth
      headerCells[index].style.width = `${cell.offsetWidth}px`
    })
    if (headerTable && bodyTable && bodyHeader) {
      headerTable.style.width = `${totalWidth}px`
    }
  }

  rowClick(item: any) {
    this.goToCompany(item)
  }

  created(): void {
    tableCount.getRowCount(this.tableId).then((perPage: any) => {
      this.perPage = perPage
      this.getProcurementCompanies()
    })
  }
}

interface TableField {
  key: string
  label: string
  sortable: boolean
}

