



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { Submittable } from '@/shared/interfaces/submittable';
import ProcurementService from '@/services/ProcurementService';

@Component({
  methods: { __ }
})
export default class ProcurementDeleteModal extends Vue implements Submittable {
  @Prop() itemId!: number
  @Prop() itemDetails!: any

  submitDialog(): void {
    const payload = { ...this.itemDetails.procurementDetails || {}, procurement_id: this.itemId, is_memorized: false }
    ProcurementService.saveProcurement(payload).then(() => {
      this.$root.$emit('formSuccess')
    })
  }
}
