import Service from '@/modules/service/models/service.model'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import InvoiceTemplateResponse from '../interfaces/invoice-template-response'
import Client from '@/modules/client/models/client.model'
import User from '@/modules/user/models/user.model'
import { InvoiceTemplateTypeData } from '../config/invoice-template.config'
import moment from 'moment'
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';

export default class InvoiceTemplate extends Model<IModelResponse> {
  static ALIAS: string = 'invoice-template'

  client_id!: number
  client!: Client
  manager_id!: number
  manager!: User
  date!: string
  pay_in_days!: number
  generate_every_months!: number
  active!: string | boolean
  notes!: string
  locale!: string
  items!: IServiceResponse[]
  discount_type!: string
  discount!: number | null
  discount_percentage!: number | null
  newInvoiceItem!: IServiceResponse
  total!: string
  total_with_vat!: string
  total_vat!: string

  transform(data: InvoiceTemplateResponse): void {
    super.transform(data)
    this.newInvoiceItem = {
      title: '',
      amount: null,
      price_per_unit: null,
      total_with_vat: 0,
      total: 0,
      discount_percentage: 0,
      price_per_unit_with_vat: 0,
      unit: '',
      vat: null
    }

    this.client_id = data.client_id
    this.manager_id = data.manager_id
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.pay_in_days = data.pay_in_days
    this.generate_every_months = data.generate_every_months
    this.active = data.active || false
    this.notes = data.notes
    this.locale = data.locale
    this.items = data.items || [{ ...this.newInvoiceItem }]
    this.client = this.setRelation(Client, data.client)
    this.manager = this.setRelation(User, data.manager)
    this.discount_type = data.discount_type || 'none'
    this.discount = Number(data.discount) ? data.discount : null
    this.discount_percentage = Number(data.discount_percentage) ? data.discount_percentage : null
    this.total = data.total || '0.00'
    this.total_with_vat = data.total_with_vat || '0.00'
    this.total_vat = data.total_vat || '0.00'
  }
}

export class InvoiceTemplateTable extends Model<IModelResponse> {
  clientId!: number
  client!: Client
  managerId!: number
  manager!: User
  date!: string
  payInDays!: number
  generateEveryMonths!: number
  active!: string
  notes!: string
  locale!: string
  items!: Service[]
  total!: string
  total_with_vat!: string

  transform(data: InvoiceTemplateResponse): void {
    super.transform(data)

    this.clientId = data.client_id
    this.managerId = data.manager_id
    this.date = data.date
    this.payInDays = data.pay_in_days
    this.generateEveryMonths = data.generate_every_months
    this.active = InvoiceTemplateTypeData[String(data.active)]
    this.notes = data.notes
    this.locale = data.locale
    this.items = this.setRelation(Service, data.items)
    this.client = this.setRelation(Client, data.client)
    this.manager = this.setRelation(User, data.manager)
    this.total = data.total
    this.total_with_vat = data.total_with_vat
  }
}
