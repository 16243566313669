











import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import { InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { Route } from 'vue-router'
import highlightOverdue from '@/shared/helpers/data-table/highlight-overdue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable },
  methods: { __ }
})
export default class ClientInvoices extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.client && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientInvoices)
      .setModel(Invoice)
      .setEndpoint(`/clients/${this.$route.params.id}/invoices`)
      .setFilter([{ operator: FilterOperators.equals, type: 'type', value: InvoiceTypes.standard }])
      .setRowClassCallback(highlightOverdue)
      .setRowClickCallback((item:Invoice) => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.invoice, linkParam: 'id' }),
        new DataTableHeader().setKey('total').setLabel(__('views.invoices.index.table.columns.total')),
        new DataTableHeader().setKey('total_with_vat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('payed').setLabel(__('views.invoices.index.table.columns.payed')),
        new DataTableHeader().setKey('left_pay').setLabel(__('views.invoices.index.table.columns.leftPay')),
        new DataTableHeader().setKey('date').setLabel(__('views.invoices.index.table.columns.date')),
        new DataTableHeader().setKey('due_date').setLabel(__('views.invoices.index.table.columns.dueDate')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.invoices.index.table.columns.status'))
          .setFunctionalClasses((data: Invoice) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: Invoice) => {
            if (data.status === 'paid') return __('common-names.paid')

            if (data.status === 'partially-paid') return __('common-names.partially-paid')

            if (data.status === 'not-approved') return __('common-names.unapproved')

            return __('common-names.unpaid')
          })
      ])
      .setSumCheckboxes(true)
  }

  private addNewInvoice() {
    const data: any = { client_id: this.$route.params.id }
    this.$router.push({
      name: GeneralRoutes.invoicesCreate,
      params: { data }
    })
  }
}
