










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Offer from '@/modules/offer/models/offer.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { tableCount } from '@/helpers/tableCount';
import Invoice from '@/modules/invoices/models/invoice.model';
import { InvoiceStatusKeysData, OfferStatusKeys } from '@/shared/configs/offer.config';
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter';
import Field, { FieldSizes } from '@/shared/classes/components/form/field';
import { FieldTypes } from '@/shared/components/form/field-types';
import SelectOption from '@/shared/classes/components/form/select-option';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class OffersIndex extends Vue {
  @Prop({ default: false }) showFilter?: boolean
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.offersIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.offersIndex)
      .setModel(Offer)
      .setEndpoint('/offers')
      .setPerPageUnderFilter(true)
      .setRowClickCallback((item: Offer) => {
        this.$router.push({ name: GeneralRoutes.offer, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.offers.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.offer, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('client.company_name')
          .setLabel(__('views.offers.index.table.columns.client'))
          .setLink({ link: GeneralRoutes.client, linkParam: 'client.id' }),
        new DataTableHeader().setKey('manager.full_name').setLabel(__('views.offers.index.table.columns.manager')),
        new DataTableHeader().setKey('date').setLabel(__('views.offers.index.table.columns.date')),
        new DataTableHeader().setKey('due_date').setLabel(__('views.offers.index.table.columns.due_date')),
        new DataTableHeader().setKey('total').setLabel(__('views.offers.index.table.columns.total')),
        new DataTableHeader().setKey('total_with_vat').setLabel(__('views.offers.index.table.columns.total_with_vat')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.offers.index.table.columns.status'))
          .setFunctionalClasses((data: Invoice) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: Invoice) => {
            if (data.status === OfferStatusKeys.active) return __(InvoiceStatusKeysData[OfferStatusKeys.active])
            if (data.status === OfferStatusKeys.approved) return __(InvoiceStatusKeysData[OfferStatusKeys.approved])
            if (data.status === OfferStatusKeys.refused) return __(InvoiceStatusKeysData[OfferStatusKeys.refused])

            return __(InvoiceStatusKeysData[OfferStatusKeys.suspended])
          })
      ])

    this.table
      .setFilterAlwaysOpen(true)
      .setSearchAlwaysVisible(true)
      .setFilter([
        new DataTableFilter()
          .setFilterReturnParam(this.dateFilter)
          .setFilterSetValue(this.dateSetFilter)
          .setField(
            new Field()
              .setType(FieldTypes.dateFilter)
              .setSize(FieldSizes.half)
              .setTitle('Data')
              .setKey('date')
              .setSelectOptions([
                new SelectOption().setKey('current-month').setTitle('Einamasis mėnuo'),
                new SelectOption().setKey('last-month').setTitle('Praėjęs mėnuo'),
                new SelectOption().setKey('select').setTitle('Pasirinktina data')
              ])
          )
      ])
      .setSumCheckboxes(true)
  }

  addNewOffer(): void {
    this.$router.push({ name: GeneralRoutes.offersCreate })
  }

  dateFilter(data: any) {
    const from = data.from ? { [`filter.${FilterOperators.gte}.date`]: data.from } : ''
    const to = data.to ? { [`filter.${FilterOperators.lte}.date`]: data.to } : ''
    return { ...from, ...to }
  }

  dateSetFilter(field: any, data: any, operator?: string) {
    if (operator === FilterOperators.gte) {
      field.value = {
        ...field.value,
        from: data
      }
    }

    if (operator === FilterOperators.lte) {
      field.value = {
        ...field.value,
        to: data
      }
    }
  }
}
