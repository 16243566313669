


























































































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import ModalNavigationable from '@/shared/components/modal-route/ModalNavigationable.vue'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import RequestService from '@/services/RequestService';
import RequestItem from '@/modules/requests/models/request.model';
import TextareaField from '@/shared/components/form/field-types/TextareaField.vue';
import AreaField from '@/shared/components/form/fields/AreaField.vue';
import { GeneralRoutes } from '@/router/routes/general';
import IRequestPayload from '@/modules/requests/interfaces/request-payload.interface';
import { Submittable } from '@/shared/interfaces/submittable';
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import moment from 'moment/moment';

@Component({
  components: { AreaField, TextareaField, ModalNavigationable, Form },
  methods: { __ }
})
export default class RequestEdit extends Vue implements Submittable {
  FieldSizes = FieldSizes
  error: any = null
  request: RequestItem | null = null

  async created(): Promise<void> {
    this.request = await RequestService.getRequest(this.$route.params.id)
    this.request.reminder_date = this.request.reminder_date ? this.request.reminder_date?.split('T')[0] : moment(this.request.created_at).format('YYYY-MM-DD')
  }

  get requestPayload(): IRequestPayload | null {
    if (this.request === null) {
      return null
    }
    let output: IRequestPayload = {
      source: this.request.source,
      description: this.request.description,
      status: this.request.status,
      manager_id: this.request.manager_id,
      offer_id: this.request.offer_id,
      phone: this.request.phone,
      email: this.request.email,
      send_reminder: this.request.send_reminder,
      reminder_date: this.request.send_reminder ? this.request.reminder_date : ''
    }

    if (this.request.client_id === null) {
      output = {
        ...output,
        client_name: this.request.client_name
      }
      return output
    }
    output = {
      ...output,
      client_id: this.request.client_id
    }

    return output
  }

  async submit(): Promise<void> {
    if (!this.requestPayload) {
      return
    }
    await RequestService.updateRequest(this.requestPayload, this.$route.params.id)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.requestIndex })
      })
      .catch((error: any) => (this.error = error))
  }

  private onClientSelect(clientId: string): void {
    http.get(`/clients/${clientId}`)
      .then((response: IResponse<IModelResponse>) => {
        if (this.request) {
          this.request.phone = response.data.data?.phone || '-'
          this.request.email = response.data.data?.company_email || '-'
          this.request.client_name = ''
        }
      })
  }

  submitDialog(): void {
    if (this.requestPayload !== null) {
      RequestService.updateRequest(this.requestPayload, this.$route.params.id)
        .then(() => {
          this.$root.$emit('formSuccess')
        })
        .catch((error: any) => (this.error = error))
    }
  }
}
