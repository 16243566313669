var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[(_vm.data)?_c('FormTemplate',{attrs:{"error":_vm.error},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('TextField',{attrs:{"id":"title","title":_vm.__('views.documents.form.title'),"required-decoration":true,"props":props},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),(_vm.data.allowedKeys.client)?_c('SearchableField',{attrs:{"id":"client_id","title":_vm.__('views.documents.form.client'),"required-decoration":true,"props":props,"loadItemsProps":{
            endpoint: 'clients',
            value: 'id',
            title: 'company_name'
          }},on:{"searchableValue":_vm.setClientId},model:{value:(_vm.data.clientId),callback:function ($$v) {_vm.$set(_vm.data, "clientId", $$v)},expression:"data.clientId"}}):_vm._e(),(_vm.data.allowedKeys.employee)?_c('SearchableField',{attrs:{"id":"employee_id","title":_vm.__('views.documents.form.worker'),"required-decoration":true,"props":props,"loadItemsProps":{
            endpoint: 'employees',
            value: 'id',
            title: 'full_name'
          }},model:{value:(_vm.data.employeeId),callback:function ($$v) {_vm.$set(_vm.data, "employeeId", $$v)},expression:"data.employeeId"}}):_vm._e(),(_vm.data.allowedKeys.date)?_c('DateField',{attrs:{"id":"date","required-decoration":true,"props":props,"title":_vm.__('views.documents.form.date'),"size":_vm.FieldSizes.half},model:{value:(_vm.data.date),callback:function ($$v) {_vm.$set(_vm.data, "date", $$v)},expression:"data.date"}}):_vm._e(),(_vm.data.allowedKeys.expiredAt && _vm.data.documentTemplateId)?_c('DateField',{attrs:{"props":props,"id":"date","title":_vm.__('views.documents.form.expires-at'),"size":_vm.FieldSizes.half},model:{value:(_vm.data.expired_at),callback:function ($$v) {_vm.$set(_vm.data, "expired_at", $$v)},expression:"data.expired_at"}}):_vm._e(),(_vm.data.allowedKeys.manager)?_c('SearchableField',{attrs:{"id":"manager_id","title":_vm.__('views.documents.form.manager'),"required-decoration":true,"props":props,"loadItemsProps":{
            plainFilter: '&filter[equals.disabled]=0',
            endpoint: 'users',
            value: 'id',
            title: 'full_name'
          }},model:{value:(_vm.data.managerId),callback:function ($$v) {_vm.$set(_vm.data, "managerId", $$v)},expression:"data.managerId"}}):_vm._e(),_c('TextField',{attrs:{"id":"number","required-decoration":true,"props":props,"title":_vm.__('views.documents.form.number')},model:{value:(_vm.data.number),callback:function ($$v) {_vm.$set(_vm.data, "number", $$v)},expression:"data.number"}}),(_vm.data.allowedKeys.offer)?_c('SearchableField',{attrs:{"id":"offer_id","title":_vm.__('views.documents.form.offer'),"required-decoration":true,"props":props,"loadItemsProps":{
            endpoint: 'offers',
            value: 'id',
            title: 'title',
             plainFilter: _vm.data.clientId ? ("&filter[equals.client_id]=" + (_vm.data.clientId)) : ''
          }},model:{value:(_vm.data.offerId),callback:function ($$v) {_vm.$set(_vm.data, "offerId", $$v)},expression:"data.offerId"}}):_vm._e(),_vm._l((_vm.data.keys),function(customField){return _c('TextField',{key:customField.key,attrs:{"required-decoration":true,"props":props,"id":customField.key,"title":customField.title},model:{value:(customField.value),callback:function ($$v) {_vm.$set(customField, "value", $$v)},expression:"customField.value"}})}),_c('RichEditorField',{attrs:{"id":"content","title":_vm.__('views.documents.form.content'),"required-decoration":true,"props":props,"keys":_vm.keys},model:{value:(_vm.data.content),callback:function ($$v) {_vm.$set(_vm.data, "content", $$v)},expression:"data.content"}})]}},{key:"actionsLeft",fn:function(){return [_c('button',{staticClass:"button button--orange mr-10 minw-120",attrs:{"type":"button"},on:{"click":_vm.openSaveTemplateModal}},[_vm._v(" "+_vm._s(_vm.__('form.save-template'))+" ")])]},proxy:true}],null,false,2034752776)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }