







































































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DocumentTemplate from './models/document-template.model'
import User from '../user/models/user.model'
import _ from 'lodash'
import DocumentClass from './models/document.model'
import { DefaultTagValues } from '@/shared/configs/documents.config'
import Client from '../client/models/client.model'
import CustomKey from './models/custom-keys.model'
import { GeneralRoutes } from '@/router/routes/general'
import DocumentService from '@/services/DocumentService'
import IDocumentTemplateResponse from './interfaces/document-template-response.interface'
import DocumentSaveAsTemplate from '@/modules/documents/DocumentSaveAsTemplate.vue';
import { FieldSizes } from '@/shared/classes/components/form/field';

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DocumentCreate extends Vue {
  data: DocumentClass = new DocumentClass()
  templateData: DocumentTemplate[] = []
  templateMeta: any = null
  userData: User[] = []
  userMeta: any = null
  clientData: Client[] = []
  clientMeta: any = null
  error: any = null
  FieldSizes = FieldSizes
  clientId: null | number = null

  setClientId(id: number) {
    this.clientId = id
  }

  created(): void {
    this.data.offerId = Number(this.$route.params.offerId) || undefined
    this.data.clientId = Number(this.$route.params.clientId) || undefined
  }

  setTemplate(template: IDocumentTemplateResponse): void {
    this.data.setTemplate(new DocumentTemplate(template))
  }

  get keys(): string[] {
    let keysResult: string[] = []
    _.each(this.data.allowedKeys, (allowed: boolean, key: string) => {
      if (allowed) keysResult = [...keysResult, ...DefaultTagValues[key].keys]
    })

    const customKeys: string[] = []
    _.each(this.data.keys, (key: CustomKey) => {
      customKeys.push(`{${key.key}}`)
    })

    customKeys.push('{company_director_sign}')

    return keysResult.concat(customKeys)
  }

  async submit(): Promise<void> {
    const payload = _.cloneDeep(this.data)
    if (!this.data.allowedKeys.expiredAt || !this.data.documentTemplateId) {
      delete payload.expired_at
    }
    await DocumentService.createDocument(payload.convertToSnakeCase())
      .then((document: DocumentClass) => {
        if (document.id) this.$router.push({ name: GeneralRoutes.document, params: { id: document.id.toString() } })
      })
      .catch((error: any) => (this.error = error))
  }

  openSaveTemplateModal(): void {
    this.$store.commit('setModalDialog', {
      component: DocumentSaveAsTemplate,
      id: this.data.id,
      props: {
        title: __('views.offers.save-template.title'),
        size: 'md',
        submitLabel: __('form.save')
      },
      details: {
        content: this.data.content
      }
    })
  }
}
