

















































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import _ from 'lodash'
import DocumentTemplate from './models/document-template.model'
import CustomKey from './models/custom-keys.model'
import { DefaultTagValues } from '@/shared/configs/documents.config'
import { GeneralRoutes } from '@/router/routes/general'
import Mask from '@/shared/classes/mask'
import DocumentTemplateService from '@/services/DocumentTemplateService'
import { FieldSizes } from '@/shared/classes/components/form/field'
import http from '@/shared/helpers/http';

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DocumentTemplateCreate extends Vue {
  data: DocumentTemplate = new DocumentTemplate()
  DefaultTagValues = DefaultTagValues
  maskData: Mask[] = []
  maskMeta: any = null
  FieldSizes = FieldSizes
  error: any = null

  addNewKey(): void {
    if (this.data.keys) this.data.keys.push(new CustomKey())
  }

  async created(): Promise<void> {
    if (!this.$route.query.copy_id) {
      return
    }
    http.get(`document-templates/${this.$route.query.copy_id}`).then(response => {
      this.data = new DocumentTemplate(response.data.data)
      this.$root.$emit('headingChange', {
        heading: __('views.documents.templates.copy.title', { template: this.title })
      })
    })
  }

  get title() {
    return this.data.title || ''
  }

  get keys(): string[] {
    const defaultKeys = this.data.defaultKeys
    let keysResult: string[] = []
    if (defaultKeys) {
      Object.keys(defaultKeys).forEach((key: string) => {
        // @ts-ignore
        if (defaultKeys[key] === '1') keysResult = [...keysResult, ...DefaultTagValues[key].keys]
      })
    }

    const customKeys: string[] = []
    if (this.data.keys) {
      _.each(this.data.keys, (key: CustomKey) => {
        if (key.key) customKeys.push(`{${key.key}}`)
      })
    }

    customKeys.push('{company_director_sign}')

    return keysResult.concat(customKeys)
  }

  async submit(): Promise<void> {
    delete this.data.expired_at
    // @ts-ignore
    delete this.data.expiredAt
    await DocumentTemplateService.submitDocumentTemplate(this.data)
      .then(() => this.$router.push({ name: GeneralRoutes.documentsTemplatesIndex }))
      .catch((error: any) => {
        this.error = error
      })
  }
}
