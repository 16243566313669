import http from '@/shared/helpers/http';
import { AxiosResponse } from 'axios';
import Invoice from '@/modules/invoices/models/invoice.model';
import { IUnitOption, IVatOption } from '@/modules/invoices/interfaces/invoice-response.interface';
import InvoiceTemplate from '@/modules/invoice-templates/models/invoice-template.model';

export default class InvoicesService {
  static async sendInvoices(payload: Object): Promise<AxiosResponse> {
    return http.post('invoices/reminder', payload)
  }

  static async createInvoice(payload: Invoice): Promise<Invoice> {
    return http.post('invoices', payload).then(response => response.data.data)
  }

  static async updateInvoice(payload: Invoice, id: number): Promise<Invoice> {
    return http.put(`invoices/${id}`, payload).then(response => response.data.data)
  }

  static async getInvoice(id: number): Promise<Invoice> {
    return http.get(`invoices/${id}`).then(response => response.data.data)
  }

  static async getVatOptions(): Promise<IVatOption[]> {
    return http.get('/vats', { params: { perPage: 999 } }).then(response => response.data.data)
  }

  static async getUnitOptions(): Promise<IUnitOption[]> {
    return http.get('/units', { params: { perPage: 999 } }).then(response => response.data.data)
  }

  static async createInvoiceTemplate(payload: InvoiceTemplate): Promise<InvoiceTemplate> {
    return http.post('invoice-templates', payload).then(response => response.data.data)
  }

  static async updateInvoiceTemplate(payload: InvoiceTemplate, id: number): Promise<InvoiceTemplate> {
    return http.put(`invoice-templates/${id}`, payload).then(response => response.data.data)
  }

  static async getInvoiceTemplate(id: number): Promise<InvoiceTemplate> {
    return http.get(`invoice-templates/${id}`).then(response => response.data.data)
  }
}
