import http from '@/shared/helpers/http'
import ProcurementCategory from '@/modules/procurement/interfaces/procurement-category.interface';
import ProcurementSavePayload from '@/modules/procurement/interfaces/procurement-save-payload.interface';
import ProcurementData from '@/modules/procurement/interfaces/procurement-data.interface';
import SavedProcurementCategory from '@/modules/procurement/interfaces/saved-procurement-category.interface';

export default class ProcurementService {
  static async getCategories(): Promise<ProcurementCategory> {
    return http.get('procurements/categories').then((response) => response.data.data)
  }

  static async getSavedCategories(): Promise<SavedProcurementCategory[]> {
    return http.get('procurement-categories').then((response) => response.data.data)
  }

  static async saveCategories(data: object): Promise<any> {
    return http.post('procurement-categories', data).then((response) => response.data.data)
  }

  static async deleteCategories(data: object): Promise<any> {
    return http.delete('procurement-categories', { data })
  }

  static async getSavedCategoryProcurements(perPage: number = 15, page: string = '1', dateRange: string | undefined = undefined, q: string | undefined = undefined, code: string | undefined = undefined): Promise<any> {
    return http.get('procurements/saved', { params: { perPage, page, 'between[published_at]': dateRange, q, 'filter[bvpzCode]': code } }).then((response) => response.data)
  }

  static async getItemsByCategory(categories: string, page: number): Promise<any> {
    return http.get('procurements/by-categories', { params: { categories, page } }).then((response) => response.data)
  }

  static async saveProcurement(payload: ProcurementSavePayload): Promise<any> {
    return http.post('procurement-details', payload).then((response) => response.data)
  }

  static async getSavedProcurements(perPage: number = 15, page: number = 1): Promise<any> {
    return http.get('procurements/memorized', { params: { perPage, page } }).then((response) => response.data)
  }

  static async getProcurementContent(id: string): Promise<ProcurementData> {
    return http.get(`procurements/${id}`).then((response) => response.data.data)
  }

  static async getMemorizedCompanies(params: any): Promise<any> {
    return http.get('procurement-companies/memorized', { params }).then((response) => response.data)
  }

  static async memorizeCompany(payload: any): Promise<any> {
    return http.post('procurement-companies', payload).then((response) => response.data)
  }

  static async getProcurementCompany(id: number): Promise<any> {
    return http.get(`procurement-companies/companies/${id}`).then((response) => response.data)
  }

  static async getProcurementCompanyProcurements(id: number, page: number = 1): Promise<any> {
    return http.get(`procurements?filter[equals][company_id]=${id}`, { params: { page } }).then((response) => response.data)
  }

  static async getMemorizedProcurementCompanyProcurements(id: number, page: number = 1): Promise<any> {
    return http.get(`procurements/memorized?filter[equals][company_id]=${id}`, { params: { page } }).then((response) => response.data)
  }
}
