







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ProcurementSelect from '@/modules/procurement/ProcurementSelect.vue';
import { IMeta } from '@/shared/interfaces/response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import ProcurementService from '@/services/ProcurementService';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import __ from '@/helpers/__';
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import ProcurementListLoader from '@/modules/procurement/ProcurementListLoader.vue';
import ProcurementListItem from '@/modules/procurement/ProcurementListItem.vue';
import ProcurementSaveModal from '@/modules/procurement/ProcurementSaveModal.vue';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';

@Component({
  components: { LoadingSpinner, ProcurementListItem, ProcurementListLoader, TableLoader, ChoiceToggle, Pagination, ProcurementSelect },
  methods: { __ }
})
export default class ProcurementListInner extends Vue {
  @Prop() data?: ProcurementListItemData[]
  @Prop() meta?: IMeta
  @Prop() canSave?: boolean
  @Prop() isMemorizing?: boolean
  @Prop() loading?: boolean

  GeneralRoutes = GeneralRoutes

  fields: fieldType[] = [
    { key: 'title', label: __('views.procurement.table.title') },
    { key: 'bvpz_code', label: __('views.procurement.table.bvpz_code') },
    { key: 'created_at', label: __('views.procurement.table.date') },
    { key: 'details', label: __('views.procurement.table.submit_date') },
    { key: 'id', label: '' }
  ]

  onOwnerCLick(owner: string) {
    this.$root.$emit('procurementOwnerClick', owner)
  }

  onCodeCLick(code: string) {
    this.$root.$emit('procurementCodeClick', code)
  }

  @Watch('$route.query.page') private onPageChange(newVal: string): void {
    if (typeof newVal !== 'undefined') {
      this.$emit('procurementListPageChange', newVal)
    }
  }

  private openSaveModal(id: number): void {
    this.$store.commit('setModalDialog', {
      component: ProcurementSaveModal,
      id,
      props: {
        size: 'md',
        title: __('views.procurement.table.memorize'),
        onSuccess: () => {
          this.$emit('refreshProcurements')
        }
      },
      details: {
        procurementId: id
      }
    })
  }

  private onSaveClick(id: number, isMemorized: boolean):void {
    if (!isMemorized) {
      this.openSaveModal(id)
      return
    }
    this.toggleProcurementSave(id, isMemorized)
  }

  private toggleProcurementSave(id: number, isMemorized: boolean):void {
    if (typeof this.data !== 'undefined') {
      ProcurementService.saveProcurement({
        procurement_id: id,
        is_memorized: !isMemorized
      }).then(() => {
        this.$emit('refreshProcurements')
      })
    }
  }
}

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}
