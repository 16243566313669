


























































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DocumentTemplate from './models/document-template.model'
import User from '../user/models/user.model'
import _ from 'lodash'
import DocumentClass from './models/document.model'
import { DefaultTagValues } from '@/shared/configs/documents.config'
import Client from '../client/models/client.model'
import DocumentService from '@/services/DocumentService'
import CustomKey from './models/custom-keys.model'
import { GeneralRoutes } from '@/router/routes/general'
import DocumentSaveAsTemplate from '@/modules/documents/DocumentSaveAsTemplate.vue';
import { FieldSizes } from '@/shared/classes/components/form/field';

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DocumentEdit extends Vue {
  data: DocumentClass | null = null
  templateData: DocumentTemplate[] = []
  templateMeta: any = null
  userData: User[] = []
  userMeta: any = null
  clientData: Client[] = []
  clientMeta: any = null
  error: any = null
  FieldSizes = FieldSizes
  clientId: null | number = null

  setClientId(id: number) {
    this.clientId = id
  }

  async created(): Promise<void> {
    this.data = await DocumentService.getDocument(this.$route.params.id)

    this.$root.$emit('headingChange', {
      heading: __('views.documents.edit.title', { document: this.title })
    })
  }

  get keys(): string[] {
    let keysResult: string[] = []
    _.each(this.data?.allowedKeys, (allowed: boolean, key: string) => {
      if (allowed) keysResult = [...keysResult, ...DefaultTagValues[key].keys]
    })

    const customKeys: string[] = []
    _.each(this.data?.keys, (key: CustomKey) => {
      customKeys.push(`{${key.key}}`)
    })

    customKeys.push('{company_director_sign}')

    return keysResult.concat(customKeys)
  }

  async submit(): Promise<void> {
    if (this.data) {
      const payload = _.cloneDeep(this.data)
      if (!this.data.allowedKeys.expiredAt || !this.data.documentTemplateId) {
        delete payload.expired_at
        // @ts-ignore
        delete payload.expiredAt
      }
      await DocumentService.updateDocument(payload.convertToSnakeCase(), this.$route.params.id)
        .then(() => {
          this.$router.push({ name: GeneralRoutes.document, params: { id: this.$route.params.id } })
        })
        .catch((error: any) => (this.error = error))
    }
  }

  get title(): string {
    return this.data?.number || ''
  }

  openSaveTemplateModal(): void {
    if (this.data) {
      this.$store.commit('setModalDialog', {
        component: DocumentSaveAsTemplate,
        id: this.data.id,
        props: {
          title: __('views.offers.save-template.title'),
          size: 'md',
          submitLabel: __('form.save')
        },
        details: {
          content: this.data.content
        }
      })
    }
  }
}
