


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ProcurementSelect from '@/modules/procurement/ProcurementSelect.vue';
import { IMeta } from '@/shared/interfaces/response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import ProcurementService from '@/services/ProcurementService';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import ProcurementSavePayload from '@/modules/procurement/interfaces/procurement-save-payload.interface';
import __ from '@/helpers/__';
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import ProcurementListLoader from '@/modules/procurement/ProcurementListLoader.vue';
import ProcurementListItem from '@/modules/procurement/ProcurementListItem.vue';
import ProcurementSaveModal from '@/modules/procurement/ProcurementSaveModal.vue';

@Component({
  components: { ProcurementListItem, ProcurementListLoader, TableLoader, ChoiceToggle, Pagination, ProcurementSelect },
  methods: { __ }
})
export default class ProcurementList extends Vue {
  @Prop() data?: ProcurementListItemData[]
  @Prop() meta?: IMeta
  @Prop() canSave?: boolean

  private savedProcurements: number[] = []
  private loading: boolean = true
  GeneralRoutes = GeneralRoutes

  fields: fieldType[] = [
    { key: 'title', label: __('views.procurement.table.title') },
    { key: 'contracting_authority', label: __('views.procurement.table.organisation') },
    { key: 'bvpz_code', label: __('views.procurement.table.bvpz_code') },
    { key: 'created_at', label: __('views.procurement.table.date') },
    { key: 'details', label: __('views.procurement.table.submit_date') },
    { key: 'id', label: '' }
  ]

  onCodeCLick(code: string) {
    this.$root.$emit('procurementCodeClick', code)
  }

  created(): void {
    if (this.canSave) {
      ProcurementService.getSavedProcurements(999)
        .then((response) => {
          response.data.forEach((item: ProcurementListItemData) => {
            this.savedProcurements.push(item.id)
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private openSaveModal(id: number): void {
    this.$store.commit('setModalDialog', {
      component: ProcurementSaveModal,
      id,
      props: {
        size: 'md',
        title: __('views.procurement.table.memorize'),
        onSuccess: () => {
          this.savedProcurements.push(id)
          this.$emit('procurementListPageChange', this.$route.query.page || 1)
        }
      },
      details: {
        procurementId: id
      }
    })
  }

  private onSaveClick(id: number, isMemorized: boolean, details: any):void {
    if (!isMemorized) {
      this.openSaveModal(id)
      return
    }
    this.toggleProcurementSave(id, details)
  }

  @Watch('$route.query.page') private onPageChange(newVal: string): void {
    if (typeof newVal !== 'undefined') {
      this.$emit('procurementListPageChange', newVal)
    }
  }

  private updateSavedProcurements(data:ProcurementSavePayload): void {
    if (data.is_memorized) {
      this.savedProcurements.push(data.procurement_id)
      return
    }
    const procurementIndex = this.savedProcurements.indexOf(data.procurement_id);
    if (procurementIndex > -1) {
      this.savedProcurements.splice(procurementIndex, 1)
    }
  }

  private isSaved(id: number): boolean {
    if (typeof this.savedProcurements !== 'undefined') {
      return this.savedProcurements.includes(id)
    }
    return false
  }

  private toggleProcurementSave(id: number, details: any):void {
    if (typeof this.data !== 'undefined') {
      const payload = { ...details || {}, procurement_id: id, is_memorized: !this.isSaved(id) }
      ProcurementService.saveProcurement(payload).then((response) => {
        this.updateSavedProcurements({ procurement_id: id, is_memorized: response.data.is_memorized })
      })
    }
  }

  rowClick(item: any) {
    const route = this.$router.resolve({
      name: GeneralRoutes.procurementInner,
      params: { id: item.id.toString() }
    })
    window.open(route.href, '_blank')
  }
}

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}
