





















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import ClientDocument from '@/modules/client/models/client-document.model'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table';
import ClientDocumentUpload from '@/modules/client/client-tabs/ClientDocumentUpload.vue';
import DataTableAction from '@/shared/classes/components/data-table/data-table-action';
import ClientUpload from '@/modules/client/models/client-upload.model';
import DocumentService from '@/services/DocumentService';
import ClientCustomDocumentDelete from '@/modules/client/ClientCustomDocumentDelete.vue';
import { tableCount } from '@/helpers/tableCount';
import PdfPreviewModal from '@/shared/components/PdfPreviewModal.vue';

@Component({
  components: { PdfPreviewModal, DataTable },
  methods: { __ }
})
export default class ClientDocuments extends Vue {
  generatedDocumentsTable: DataTableBase | null = null
  customDocumentsTable: DataTableBase | null = null
  documentId: string | null = null

  created(): void {
    this.generatedDocumentsTable = new DataTableBase()
      .setId(tableCount.tableID.clientDocuments)
      .setModel(ClientDocument)
      .setEndpoint(`/clients/${this.$route.params.id}/documents`)
      .setDefaultSortBy('date')
      .setRowClickCallback((item:ClientDocument) => {
        this.$router.push({ name: GeneralRoutes.document, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.documents.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.document, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.clients.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.document, linkParam: 'id' }),
        new DataTableHeader().setKey('date').setLabel(__('views.clients.index.table.columns.date'))
      ])
      .setActions([
        new DataTableAction().setIcon('view mr-2').setAction(this.preview)
      ])

    this.customDocumentsTable = new DataTableBase()
      .setId(tableCount.tableID.clientDocumentsCustom)
      .setModel(ClientUpload)
      .setEndpoint(`/client-documents/by-client/${this.$route.params.id}`)
      .setDefaultSortBy('uploaded_date')
      .setRowClickCallback((file: ClientUpload) => {
        this.downloadDocument({ item: file })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.documents.form.title')),
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.documents.form.file_name')),
        new DataTableHeader().setKey('uploaded_date').setLabel(__('views.clients.index.table.columns.date'))
      ])
      .setActions([
        new DataTableAction().setIcon('view mr-2').setAction(this.preview),
        new DataTableAction().setIcon('download').setAction(this.downloadDocument),
        new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)
      ])
  }

  private addNewDocument():void {
    this.$router.push({ name: GeneralRoutes.documentsCreate, params: { clientId: this.$route.params.id } })
  }

  private addCustomDocument():void {
    this.$store.commit('setModalDialog', {
      component: ClientDocumentUpload,
      props: {
        title: __('views.documents.form.upload'),
        onSuccess: () => {
          refreshTable(this.$refs.customDocumentsTable)
        }
      }
    })
  }

  private downloadDocument(data: any) {
    const FileDownload = require('js-file-download');
    DocumentService.downloadDocument(data.item.file_id).then((response) => {
      FileDownload(response.data, data.item.title);
    });
  }

  private openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: ClientCustomDocumentDelete,
      id: row.item.id,
      props: {
        title: __('views.templates.delete.title', { offerTemplate: row.item.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.customDocumentsTable)
        }
      },
      details: {
        name: row.item.title
      }
    })
  }

  preview(item: any): void {
    this.documentId = item.item.id.toString()
    const previewModal = this.$refs.previewModal as any
    previewModal.showModal()
  }
}
