



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { PaymentMethodActions } from '@/store/modules/payment-method/payment-method.actions'
import { PaymentMethodGetters } from '@/store/modules/payment-method/payment-method.getters'
import { mapActions, mapGetters } from 'vuex'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: {
    __,
    ...mapActions({
      paymentMethodsFetch: PaymentMethodActions.fetch
    })
  },
  computed: {
    ...mapGetters({
      paymentMethods: PaymentMethodGetters.getPaymentMethods
    })
  }
})
export default class ProcurementSaveModal extends Vue implements Submittable {
  @Prop() invoice!: any
  @Prop() itemId!: number
  @Prop() itemDetails!: any

  form: FormBase | any = null
  success: boolean = false

  async created(): Promise<void> {
    this.form = new FormBase()
      .setEndpoint('procurement-details')
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('submission_at')
          .setTitle(__('views.procurement.table.submit_date'))
          .setSize(FieldSizes.full)
          .setClasses('mb-0')
          .setType(FieldTypes.date),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('is_request_required')
          .setNoCheckboxLabel(true)
          .setTitle(__('views.procurement.table.create_request'))
          .setSize(FieldSizes.half)
          .setCheckedValue(true)
          .setUncheckedValue(false)
          .setClasses('mb-0')
      ])
      .setInjectValues({
        procurement_id: this.itemId,
        is_memorized: true
      })
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
