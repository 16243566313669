






























































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import getAll from '@/shared/configs/vuex/get-all.config'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import InvoicesService from '@/services/InvoicesService';
import SelectOption from '@/shared/classes/components/form/select-option';
import _ from 'lodash';
import { IOfferInformationItem } from '@/modules/offer/interfaces/offer-response.interface';
import { Container, Draggable } from 'vue-dndrop'
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import Template from '@/modules/offer-template/models/template.model';
import OfferService from '@/services/OfferService';
import { GeneralRoutes } from '@/router/routes/general';

interface DropResult {
  removedIndex: number
  addedIndex: number
  payload: any
}

@Component({
  components: { RadioField, PageHeader, Form, LoadingSpinner, Container, Draggable },
  methods: { __ }
})
export default class TemplatesCreate extends Vue {
  @Prop() meta!: any
  FieldSizes = FieldSizes
  data: Template = new Template({})
  error: any = null
  loading: boolean = true
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []

  async submit(): Promise<void> {
    this.loading = true
    await OfferService.createOfferTemplate(this.data)
      .then((template: Template) => {
        if (template.id) this.$router.push({ name: GeneralRoutes.offerTemplatesEdit, params: { id: template.id.toString() } })
      })
      .catch((error: any) => {
        this.loading = false
        this.$nextTick(() => {
          this.error = error
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  async getOfferItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  handleDrop(dropResult: DropResult) {
    const elementArray: any = _.cloneDeep(this.data.information)
    elementArray.splice(dropResult.removedIndex, 1)
    elementArray.splice(dropResult.addedIndex, 0, this.data.information[dropResult.removedIndex])
    elementArray.forEach((item:IOfferInformationItem, index:number) => {
      item.position = index
    })
    this.data.information = elementArray
  }

  removeOfferItem(index: number): void {
    this.data.information.splice(index, 1)
  }

  onItemChange(data:IOfferInformationItem, item:IOfferInformationItem):void {
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit
  }

  addOfferHeading() {
    this.data.information.push({
      type: 'heading',
      position: this.data.information.length,
      title: ''
    })
  }

  addOfferService() {
    this.data.information.push({
      type: 'service',
      position: this.data.information.length,
      title: '',
      amount: '',
      price_per_unit: '',
      unit: '',
      vat: 0
    })
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    let vatRate:number | null = null
    this.data.information.forEach(information => {
      if (information.type !== 'service') {
        return
      }
      if (vatRate === null) {
        vatRate = information.vat || null
      }
      const itemTotal = Number(information.amount) * Number(information.price_per_unit)
      const itemTotalWithVat = itemTotal * (1 + Number(information.vat) / 100)
      information.total = itemTotal.toFixed(2)
      information.total_vat = (itemTotalWithVat - itemTotal).toFixed(2)
      information.total_with_vat = itemTotalWithVat.toFixed(2)
      newTotal += itemTotal
      newTotalWithVat += itemTotalWithVat
      newTotalVat += (itemTotalWithVat - itemTotal)
    })
    if (vatRate === null) {
      vatRate = 0
    }
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.information', { deep: true })
  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onItemsChange(): void {
    this.calculateSums()
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getOfferItemOptions()
    this.loading = false
  }
}
