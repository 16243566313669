export enum ProjectStatusKeys {
    active = 'active',
    disabled = 'disabled'
}

export const ProjectStatusKeysData: any = {
  [ProjectStatusKeys.active]: 'Aktyvus',
  [ProjectStatusKeys.disabled]: 'Neaktyvs'
}

export enum ProjectTypeKeys {
    contract = 'contract',
    programing_contract = 'programing_contract',
    warranty = 'warranty',
    without_contract = 'without_contract',
}

export const ProjectTypeKeysData: any = {
  [ProjectTypeKeys.contract]: 'Priežiūros sutartis',
  [ProjectTypeKeys.programing_contract]: 'Programavimo sutartis',
  [ProjectTypeKeys.warranty]: 'Garantija',
  [ProjectTypeKeys.without_contract]: 'Nėra sutarties'
}
