







import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Invoice from '@/modules/invoices/models/invoice.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class InvoicesRegenerateSalesDeed extends Vue implements Submittable {
  @Prop() itemId!: number
  invoice: Invoice | any = null
  dismissCountDown: number = 0

  submitDialog(): void {
    this.$root.$emit('formSuccess')
  }
}
